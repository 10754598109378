
.sidebarContainer {
    display: flex;
  }
  
  .drawer {
    width: 240px;
    flex-shrink: 0;
    border: none;
  }
  
  .drawerPaper {
    width: 240px;
    box-sizing: border-box;
    border: none;
  }
  
  .listPadding {
    padding-left: 16px;
  }
  
  .menuItem {
    padding: 8px 18px;
    margin-top: 12px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .menuItem:hover {
    opacity: 0.7;
  }
  
  @media (max-width: 900px) {
    /* Hide sidebar on mobile */
    .drawer {
      width: 0;
    }
  }
  